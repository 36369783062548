<template>
  <b-modal
    id="customer_sale_modal"
    no-close-on-backdrop
    hide-footer
    size="xl"
    scrollable
    centered
    @shown="onShownModal"
    @hidden="onHideActions"
  >
    <template #modal-title>
      <p class="h4">
        {{ $t('Visualizar Cliente') }}
      </p>
    </template>

    <b-card-actions
      :show-loading="loadingModal"
      action-collapse
      :title="`${$t('Dados do cliente')} #${person.id}`"
    >
      <customer-form
        :is-read-only="true"
        :person="person"
      />
    </b-card-actions>

    <b-card-actions
      :show-loading="loadingModal"
      action-collapse
      :title="$t('Histórico de compras')"
    >
      <history-tab :customer-id="person.id" />
    </b-card-actions>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import { alerts } from '@/mixins'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { mapActions } from 'vuex'
import { getInitialPerson } from '@/store/pages/sale/clients/client-maintain'
import CustomerForm from './CustomerForm.vue'
import HistoryTab from './client-maintain-tabs/HistoryTab.vue'

export default {
  components: { BModal, CustomerForm, BCardActions, HistoryTab },

  mixins: [alerts],

  props: {},

  data() {
    return {
      loadingModal: false,
      person: getInitialPerson(),
      localCustomerId: null,
    }
  },

  computed: {},

  methods: {
    ...mapActions('pages/sale/clients/clientMaintain', [
      'getCustomerById',
      'formatPerson',
      'cleanSaleHistory',
    ]),

    show(customerId) {
      this.localCustomerId = customerId
      this.$bvModal.show('customer_sale_modal')
    },

    // este método executa após o "show" do modal (o show possui delay)
    async onShownModal() {
      this.loadingModal = true

      try {
        await this.onHideActions()
        const data = await this.getCustomerById({
          customerId: this.localCustomerId,
          isReadOnly: true,
        })
        const personFormated = await this.formatPerson(data)
        this.person = personFormated.person
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loadingModal = false
        this.localCustomerId = null
      }
    },

    onHideModal() {
      this.onHideActions()
      this.$bvModal.hide('customer_sale_modal')
    },

    async onHideActions() {
      this.cleanSaleHistory()
      this.person = getInitialPerson()
    },
  },
}
</script>

<style lang="scss" scoped></style>
